/**
 * The main entrypoint file.
 *
 * Copyright MOS Insan Kaynaklari 2020.
 *
 * @author Efe Mert Demir
 */
import Modal from "~/js/components/Modal";
import { ready } from "~/js/lib/utils"; // Import the SCSS entrypoints.

import "~/scss/index.scss";
import "~/scss/vendor.scss";
/**
 * Add a modal for avatar uploads, when the page has editable avatars.
 *
 * @public
 */

var activateAvatarModal = function activateAvatarModal() {
  var editButton = document.querySelector(".Avatar__edit"); // Create a new form for uploads

  var edit = document.createElement("div");

  var imageText = _("Image");

  edit.innerHTML = "\n<form class=\"AvatarForm\" method=\"POST\" enctype=\"multipart/form-data\">\n  <label for=\"image\">".concat(imageText, "</label>\n  <input type=\"file\" name=\"image\" id=\"image\" />\n</form>\n");
  var modal = new Modal(_("Edit Avatar"), edit, [[_("Upload"), function () {
    // Submit handler.
    var formData = new FormData();
    var form = edit.querySelector(".AvatarForm");
    formData.append("image", form.elements[0].files[0]);
    if (editButton.dataset.userId) formData.append("user_id", editButton.dataset.userId);
    window.fetch("/user/set-avatar/", {
      method: "POST",
      credentials: "same-origin",
      body: formData
    }).then(function (resp) {
      if (resp.status === 200) {
        return resp.json();
      } else {
        return resp.json().then(function (data) {
          throw data.error;
        });
      }
    }).then(function (data) {
      // Update all avatars on the page, but only if we are the same
      // user. Otherwise, just update the avatar in the editable
      // avatar box
      var avatars;

      if (!editButton.dataset.userId) {
        avatars = document.querySelectorAll(".Avatar__default, .Avatar__image");
      } else {
        avatars = [editButton.previousElementSibling];
      }

      avatars.forEach(function (avatar) {
        if (avatar.className === "Avatar__default") avatar.className = "Avatar__image";
        avatar.src = data.thumb_path;
      });
      form.reset();
      modal.close();
    })["catch"](function (err) {
      return alert(err);
    });
  }], [_("Cancel"), function () {
    return modal.close();
  }]]); // Add the modal activation handler.

  editButton.addEventListener("click", function (e) {
    e.preventDefault();
    modal.open();
  }, false);
};

ready(function () {
  // Activate the avatar editing modal.
  if (document.querySelector(".Avatar__edit")) activateAvatarModal(); // If there is a register form with data-recaptcha on it,
  // then make sure the captcha loads once the user starts editing the
  // form. Because Google's recaptcha script is so bloated, this gives
  // us a significant page load speed advantage.

  var captchaRegisterForm = document.querySelector(".RegisterForm[data-recaptcha]");

  if (captchaRegisterForm) {
    var captchaLoaded = false;

    var handler = function handler() {
      if (captchaLoaded) return;
      var script = document.createElement("script");
      script.src = "https://www.google.com/recaptcha/api.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
      captchaLoaded = true;
    };

    captchaRegisterForm.querySelectorAll("input, select").forEach(function (el) {
      el.addEventListener("focus", handler, false);
    });
  }

  document.documentElement.classList.add("js-loaded");
});